<template>
  <exfoliants-template
    :tolerates-exfoliant-actives="toleratesExfoliantActives"
    :use-other-exfoliants="useOtherExfoliants"
    :is-use-other-exfoliants-visible="isUseOtherExfoliantsVisible"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import ExfoliantsTemplate from '@/modules/questionnaire/components/steps/common/medical-background/exfoliants/ExfoliantsTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { yes } from '@/modules/questionnaire/api/helpers';

const { requiredField } = fieldBuilder;

const FIELDS = [requiredField('toleratesExfoliantActives'), requiredField('useOtherExfoliants')];

export default {
  name: 'Exfoliants',
  components: {
    ExfoliantsTemplate
  },
  mixins: [makeStep(FIELDS)],
  computed: {
    isUseOtherExfoliantsVisible() {
      return yes(this.toleratesExfoliantActives);
    }
  },
  watch: {
    toleratesExfoliantActives() {
      if (!this.isUseOtherExfoliantsVisible) {
        this.useOtherExfoliants = '';
        this.showNextStep();
      } else {
        this.scrollTo('#other-exfoliants');
      }
    },
    useOtherExfoliants(newValue, oldValue) {
      if (!oldValue && this.toleratesExfoliantActives) {
        this.showNextStep();
      }
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'useOtherExfoliants') {
        return this.isUseOtherExfoliantsVisible;
      }

      return true;
    }
  }
};
</script>
